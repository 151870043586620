import './dashboard.css';

import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import MyPieChart from '../PieChart/piechart'
import MyBarChart from '../BarChart/barchart'
import MyLineChart from '../LineChart/linechart'
import MyMapChart from '../MapChart/mapchartx'
import Clock from '../Clock/clock';
import Request from '../../request';
import cookie from 'react-cookies'
import Marquee from "react-fast-marquee";
import { companyUnitProcess } from '../../utils/companyProcess';


function Dashboard() {
  const navigate = useNavigate();

  const [summary, setSummary] = useState({
    oil_field_count: 0,
    make_oil_field_count: 0,
    make_oil_well_count: 0,
    oil_well_count: 0,
    processed_oil: 0,
    processed_water: 0,
    unmake_oil_field_count: 0,
    unmake_oil_well_count: 0,
    processed_waterCopy: 0,
    processed_waterUnit: '',
    processed_oilCopy:0,
    processed_oilUnit:0,
  })


  const [user, setUser] = useState({
    collect_user_count: 0,
    storage_user_count: 0,
  })


  const [zone, setZone] = useState({type: 'country', name: '中国'})
  const [backToChinaCount, setBackToChinaCount] = useState(1)

  const [record, setRecord] = useState({
    collect_record_count: 0,
    storage_record_count: 0,
    uncollect_record_count: 0,
    collected_record_count: 0,
    unstorage_record_count: 0,
    storaged_record_count: 0
  })


  const [oilFieldHistory, setOilFieldHistory] = useState({
    oil: 0,
    water: 0,
    oilCopy: 0,
    waterCopy: 0,
    oilUnit: "",
    waterUnit: "",
  })


  const [valuableOilField, setValuableOilField] = useState<any[]>([])

  const [oilPrice, setOilPrice] = useState("")

  let [selectedIndex, setSelectedIndex] = useState(0)


  const width_x = 1394
  const height_y = 784

  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientWidth * height_y / width_x,
  })


  const [scaleRate, setScaleRate] = useState(1)

  useEffect(()=>{
    let scaleRate = Math.round(size.width / width_x * 100) / 100

    // console.log('scale_rate=', scaleRate)
    setScaleRate(scaleRate)
  }, [size])


  const onResize = useCallback(() => {

    // console.log('width,height=', document.documentElement.clientWidth, document.documentElement.clientHeight)
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientWidth * height_y / width_x,
    })
  }, [])

  useEffect(() => {
    // console.log('ssss=', size);
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize',  onResize)
    }
  }, [onResize])

  useEffect(()=>{
    if(!cookie.load('token')){
      navigate('/user/login')
      return
    }

    let request = new Request({});

    request.get('/api/v1/report/summary/').then((res: any)=>{
        let companyObj:any = companyUnitProcess(res.data.processed_water)
        res.data.processed_waterCopy = companyObj.number
        res.data.processed_waterUnit = companyObj.companyUnit
        let oliObj:any = companyUnitProcess(res.data.processed_oil)
        res.data.processed_oilCopy = oliObj.number
        res.data.processed_oilUnit = oliObj.companyUnit
        setSummary({...res.data})
    })

    request.get('/api/v1/report/user/').then((res: any)=>{
      setUser({...res.data})
    })

    request.get('/api/v1/report/record/').then((res: any)=>{
      setRecord({...res.data})
    })

    request.get('/api/v1/report/oil_field_top_history/?top=13').then((res: any)=>{
      // setRecord({...res.data})

      if(res.code === 0){
        var originData = []
        for(var i=0; i<res.data.length; i++){
           var tmp = res.data[i]
           let copyOilObj:any = companyUnitProcess(res.data[i].processed_oil)
           tmp["processed_oilCopy"] = copyOilObj.number
           tmp["processed_oilUnit"] = copyOilObj.companyUnit
           let copyWaterObj:any = companyUnitProcess(res.data[i].processed_water)
           tmp["processed_waterCopy"] = copyWaterObj.number
           tmp["processed_waterUnit"] = copyWaterObj.companyUnit
           originData.push(tmp)
        }
        setValuableOilField(res.data)
      }
      // setValuableOilField(res.data)
    })

    request.get('/api/v1/oilprice/').then((res: any)=>{
      setOilPrice(res.data.price)
    })

  }, [])

  useEffect(()=>{
    setInterval(()=>{
      selectedIndex = selectedIndex+1
      setSelectedIndex(selectedIndex)
    }, 10000)
  }, [])

  const backToChina = () => {
    setBackToChinaCount(backToChinaCount+1)
    setZone({type: 'country', name: '中国'})
  }


  const goToDashboard2 = () => {
    navigate('/dashboard2')
  }

  return (
    <div className='dashboard-container' style={{ transform: `scale(${scaleRate})`, transformOrigin: 'top center', width: `${width_x}px` , height: `${height_y}px`  }}>
        <div className='logo'><div className='backToChina' onClick={backToChina}></div></div>
        {/* <div className='title'>军民共建绿色零碳厨房</div> */}
        <div className='count'>
          <div className='itemCount' onClick={goToDashboard2}>
            <div className='countUnit'>块</div>
            <div>{summary.oil_field_count}</div>
          </div>
          <div className='itemCount countRight' onClick={goToDashboard2}>
            <div className='countUnit'>口</div>
            <div>{summary.oil_well_count}</div>
          </div>
        </div>
        <div className='datetime'><Clock /></div>
        <div className='realtimeOilPrice' onClick={goToDashboard2}>实时油价：<div className='rmb'>{oilPrice}</div> 元/吨</div>


        <div className="oilFieldOutputCount">
          <div>已产油油田：<span className='countColor'>{summary.make_oil_field_count}</span>块</div>
          <div>未产油油田：<span className='countColor'>{summary.unmake_oil_field_count}</span>块</div>
        </div>

        <div className="employeeCount">
          <div>收油员：<span className='countColor'>{user.collect_user_count}</span>人</div>
          <div>储油员：<span className='countColor'>{user.storage_user_count}</span>人</div>
        </div>

        <div className="oilWellOutputCount">
          <div>投产油井：<span className='countColor'>{summary.oil_well_count}</span>口</div>
          <div>未投产油井：<span className='countColor'>0</span>口</div>
          <div>故障油井：<span className='countColor'>0</span>口</div>
        </div>

        <div className="recordCount">
          <div>收油单：<span className='countColor'>{record.collect_record_count}</span>单</div>
          <div>入库单：<span className='countColor'>{record.storage_record_count}</span>单</div>
        </div>

        <div className="collectRecordCount">
          <div>已处理收油单：<span className='countColor'>{record.collected_record_count}</span>单</div>
          <div>未处理入库单：<span className='countColor'>{record.uncollect_record_count}</span>单</div>
        </div>

        <div className="storageRecordCount">
          <div>已处理入库单：<span className='countColor'>{record.storaged_record_count}</span>单</div>
          <div>未处理入库单：<span className='countColor'>{record.unstorage_record_count}</span>单</div>
        </div>

        <div className="totalCount">
          <div className="totalCountItem">
            <div>累计处理废油········</div>
            <div className='totalCountItemText'>
              <div className='totalCountNumber'>{summary.processed_oilCopy.toFixed(2)}</div>
              <div className='unit'>{summary.processed_oilUnit}</div>
            </div>
          </div>

          <div className="totalCountItem">
            <div>累计处理污水········</div>
                <div className='totalCountItemText'>
                <div className='totalCountNumber'>{summary.processed_waterCopy.toFixed(2)}</div>
                <div className='unit'>{summary.processed_waterUnit}</div>
              </div>
          </div>

          <div className="totalCountItem">
            <div>累计处理油单········</div>
            <div className='totalCountItemText'>
              <div className='totalCountNumber'>{record.storage_record_count}</div>
              <div className='unit'>单</div>
            </div>
          </div>
        </div>

        <div className="todayOilPieChart"><MyPieChart id='todayOilPieChart' width='340px' height='186px' /></div>
        <div className="todayOilBarChart"><MyBarChart id='todayOilBarChart' width='260px' height='236px' /></div>
        <div className="historyLineChart"><MyLineChart id='historyLineChart' width='550px' height='270px' zone={zone} setOilFieldHistory={setOilFieldHistory}/></div>

        <div className='address'>{zone.name}</div>


        <div className='dealtOil'>
          <div className='dealtOilValue'>{summary.processed_oilCopy.toFixed(2)}</div>
          <div className='dealtOilUnit'>{oilFieldHistory.oilUnit}</div>
        </div>


        <div className='dealtWater'>
          <div className='dealtWaterValue'>{oilFieldHistory.waterCopy.toFixed(2)}</div>
          <div className='dealtWaterUnit'>{oilFieldHistory.waterUnit}</div>
        </div>


        <div className='valuableOilField'>

          <div className='valuableRow valuableFirstRow'>
            <div className='valuableColumn'>油田</div>
            <div className='valuableColumn valuableSecondColumn'>油井数量</div>
            <div className='valuableColumn valuableThirdColumn'>区域</div>
            <div className='valuableColumn'>单井产量</div>
          </div>

            {
              valuableOilField.map((data: any, index)=>{
                if(index === selectedIndex % valuableOilField.length){
                   return <div className='valuableRow selectedRow' key={index}>
                    <div className='valuableColumn'> <Marquee gradient={false}>{data.name}</Marquee> </div>
                    <div className='valuableColumn valuableSecondColumn'>{data.oil_well_count}个</div>
                    <div className='valuableColumn valuableThirdColumn'>{data.district}</div>
                    <div className='valuableColumn'>{data.average_oil}L</div>
                  </div>
                }else{
                  return <div className='valuableRow' key={index}>
                    <div className='valuableColumn'> {data.name.slice(0, 4)} </div>
                    <div className='valuableColumn valuableSecondColumn'>{data.oil_well_count}个</div>
                    <div className='valuableColumn valuableThirdColumn'>{data.district}</div>
                    <div className='valuableColumn'>{data.average_oil}L</div>
                  </div>
                }
              })
            }

        </div>


        <div className='selectedOilField'>
            <div className="firstRow">
              <div className="leftIcon"></div>
              <div className="line"></div>
              <div className="selectedTitle">{valuableOilField.length>0?valuableOilField[selectedIndex % valuableOilField.length].name: ""}</div>
              <div className="line"></div>
              <div className="rightIcon"></div>
            </div>
        </div>

        <div className='circleList'>
          <div className='circleOil'>
            <div className='circleItem'>
              <div className='circleNumber'>{valuableOilField.length>0?valuableOilField[selectedIndex % valuableOilField.length].processed_oilCopy.toFixed(2): ""}<span className='circleUnit'>{valuableOilField.length>0?valuableOilField[selectedIndex % valuableOilField.length].processed_oilUnit: "L"}</span></div>
            </div>
            <div className='circleText'>总油量</div>
          </div>

          <div className='circleWater'>
            <div className='circleItem'>
              <div className='circleNumber'>{valuableOilField.length>0?valuableOilField[selectedIndex % valuableOilField.length].processed_waterCopy.toFixed(2): ""}<span className='circleUnit'>{valuableOilField.length>0?valuableOilField[selectedIndex % valuableOilField.length].processed_waterUnit: "L"}</span></div>
            </div>
            <div className='circleText'>总水量</div>
          </div>
        </div>

        <div className="mapChart"><MyMapChart id='myMapChart' width='666px' height='446px' setZone={setZone} zone={zone}  backToChinaCount={backToChinaCount}/></div>

    </div>


  )
}

export default Dashboard;
