

// const proBaseURL = 'http://localhost:8000';
// const proBaseURL = 'https://api.citypro-tech.com';
// const proBaseURL = 'http://api-test.citypro-tech.com';
const proBaseURL = 'https://api.cityoil.com.cn';

// const ENV = 'prod'    // dev或者prod

export const BASE_URL = proBaseURL;


export const TIMEOUT = 5000;


export const COLOR_LIST = ['#FF4646', '#CB6747', '#00D64F', '#0EC8F9', '#6F5FFF', '#005DC7', '#FFD558', '#00FDDC']
export const COLOR_REVERSE_LIST = ['#00FDDC', '#FFD558', '#005DC7', '#6F5FFF', '#0EC8F9', '#00D64F', '#CB6747', '#FF4646']